
// Libaries
import { Component, Vue, namespace } from "nuxt-property-decorator";
import { Descriptions } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import PartnerTile from "~/components/molecules/text/PartnerTile.vue";
import UserComment from "~/components/molecules/text/UserComment.vue";
import ComparsionChart from "~/components/organisms/chart/ComparsionChart.vue";
import DataTile from "~/components/organisms/display/DataTile.vue";
import PriceComparisonHistoricalData from "~/components/organisms/display/PriceComparisonHistoricalData.vue";
import NewsletterSignup from "~/components/organisms/form/NewsletterSignup.vue";
import PriceNotification from "~/components/organisms/form/PriceNotification.vue";
import OilImportTable from "~/components/organisms/table/OilImportTable.vue";

import { HistoricalOilPriceData } from "~/types/HistoricalOilPriceData";
import { getHistoricalData } from "~/utils/calculateStatistics";

// Mixins
import { Jsonld } from "nuxt-jsonld";
import striptags from "striptags";
import FederalStates from "~/assets/data/federalStates.json";
import BreadCrumb from "~/components/molecules/display/BreadCrumb.vue";
import DistrictLocationsList from "~/components/molecules/display/DistrictLocationsList.vue";
import DistrictOtherLocations from "~/components/molecules/display/DistrictOtherLocations.vue";
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import {
  createBreadCrumbsStructuredData,
  formatBreadCrumbByCountyAndBundesland,
} from "~/mixins/breadcrumb";
import {
  formatTopCitiesTableData,
  getCurrentDate,
  getFormattedPercentage,
  getPriceDifference,
  getTableHeaderData,
  replaceToUmlauts,
  returnPriceFormatDE,
} from "~/mixins/formatting";
import {
  getFormattedTitle,
  getPriceComparisonText7Days,
  getPriceComparisonTextThreeMonths,
  getPriceDifferenceText,
} from "~/mixins/oilPricemixin";
import {
  translateStateName,
  translateStateToNutsCode,
} from "~/mixins/translations";
import { generateMetaTags } from "~/operations/meta-helper-functions";
import { decodePlaceName } from "~/operations/shared";

const BreadCrumbModule = namespace("breadcrumb");
const FederalStatesModule = namespace("federalstates");

@Jsonld
@Component({
  scrollToTop: true,
  components: {
    RouterLink,
    NewsletterSignup,
    PriceNotification,
    DataTile,
    UserComment,
    PartnerTile,
    ComparsionChart,
    OilImportTable,
    AkkordeonListItem,
    BreadCrumb,
    PriceComparisonHistoricalData,
    DistrictOtherLocations,
    DistrictLocationsList,
  },
  methods: {
    translateStateToNutsCode,
    translateStateName,
    getTableHeaderData,
    formatTopCitiesTableData,
  },
})
export default class FederalStateView extends Vue {
  head() {
    return {
      ...generateMetaTags(this.$route.path, "federal-states", [
        { k: "category", v: translateStateName(this.$route.params.category) },
      ]),
      link: [
        {
          rel: "canonical",
          href: `/heizoelpreise/bundeslaender/${this.$route.params.category.toLowerCase()}`,
        },
      ],
      script: [
        {
          json: {
            "@context": "http://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: `Was kostet Heizöl in ${translateStateName(
                  this.$route.params.category,
                )}?`,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: striptags(this.questionListWithAnswers[0].answer),
                },
              },
            ],
          },
          type: "application/ld+json",
        },
        {
          json: {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: createBreadCrumbsStructuredData(this.breadCrumb),
          },
          type: "application/ld+json",
        },
      ],
    };
  }

  @BreadCrumbModule.Mutation("setBreadCrumbForBundeslandAndCounty")
  setBreadCrumbForBundeslandAndCounty;

  @BreadCrumbModule.State("breadCrumb")
  breadCrumb;

  @FederalStatesModule.State("stateYearStatistics")
  stateYearStatistics;

  @FederalStatesModule.State("statesOilPriceHistory")
  statesOilPriceHistory;

  @FederalStatesModule.State("stateAssetsPrice")
  stateAssetsPrice;

  @FederalStatesModule.State("districtCities")
  districtCities;

  Descriptions = Descriptions;

  monthsQuantity = 6;

  questionsList = [];

  translateStateName = translateStateName;
  getTableHeaderData = getTableHeaderData;
  formatTopCitiesTableData = formatTopCitiesTableData;

  async fetch() {
    this.redirectToWithoutUmlauts();

    if (
      !FederalStates.find(
        (state) =>
          state.title.toLocaleLowerCase() ===
          replaceToUmlauts(this.$route.params.category),
      )
    ) {
      this.$nuxt.error({
        statusCode: 404,
        message: "Dieses Bundesland wurde nicht gefunden",
      });
    }
    await this.fetchCurrentStateOilPrice(
      translateStateToNutsCode(replaceToUmlauts(this.$route.params.category)),
    );
    await this.fetchChartYearAssets();

    const crumbs = formatBreadCrumbByCountyAndBundesland(
      this.$route.fullPath,
      this.$router,
    );

    this.setBreadCrumbForBundeslandAndCounty({
      breadCrumbData: crumbs,
      fullPath: this.$route.fullPath,
    });
  }

  async mounted() {
    const category = replaceToUmlauts(this.$route.params.category);
    const nutsCode = translateStateToNutsCode(category);
    const stateName = translateStateName(category);

    try {
      await Promise.all([
        this.fetchChartAssets(),
        this.fetchTopUserComments(nutsCode),
        this.fetchTopPartners(nutsCode),
        this.fetchCounties(nutsCode),
        this.fetchCities(stateName),
        this.fetchTopCities(stateName),
        this.fetchStateArticle(stateName),
        this.fetchDistrictCities(nutsCode),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  /**
   * Redirects to the URL without umlauts in the category parameter.
   * If the current category parameter contains umlauts, it will be decoded and redirected.
   * This method is used to handle URL redirection for categories with umlauts.
   */
  redirectToWithoutUmlauts() {
    if (
      this.$route.params.category !==
      decodePlaceName(this.$route.params.category)
    ) {
      this.$nuxt.context.redirect(
        301,
        decodePlaceName(this.$route.params.category),
      );
    }
  }

  async fetchChartAssets() {
    await this.$store.dispatch("federalstates/loadNationAndStateData", {
      nutsCode: translateStateToNutsCode(
        replaceToUmlauts(this.$route.params.category),
      ),
      months: this.monthsQuantity,
    });
  }
  async fetchChartYearAssets() {
    await this.$store.dispatch("federalstates/fetchStateYearData", {
      nutsCode: translateStateToNutsCode(
        replaceToUmlauts(this.$route.params.category),
      ),
    });
  }

  async fetchTopPartners(stateName) {
    await this.$store.dispatch("federalstates/fetchTopPartners", stateName);
  }

  async fetchTopUserComments(stateName) {
    await this.$store.dispatch("federalstates/fetchTopUserComments", stateName);
  }

  async fetchStateArticle(stateName) {
    await this.$store.dispatch("federalstates/fetchStateArticle", stateName);
  }

  async fetchTopCities(stateName) {
    await this.$store.dispatch("federalstates/fetchTopStateCities", stateName);
  }

  async fetchCounties(statesNutsCode) {
    await this.$store.dispatch("federalstates/fetchCountyLinks", {
      statesNutsCode,
    });
  }

  async fetchDistrictCities(stateNutsCode) {
    await this.$store.dispatch("federalstates/fetchDistrictCities", {
      stateNutsCode,
      includeLinkField: true,
    });
  }

  async fetchCities(statesName) {
    await this.$store.dispatch("federalstates/fetchBigCityLinks", statesName);
  }

  async fetchCurrentStateOilPrice(statesNutsCode: any) {
    await this.$store.dispatch(
      "federalstates/fetchCurrentStateOilPrice",
      statesNutsCode,
    );
  }

  changeMonthsQuantity(monthsQuantity: string): void {
    this.monthsQuantity = Number(monthsQuantity);
    this.fetchChartAssets();
  }

  get questionListWithAnswers() {
    const currentPriceValue =
      this.$store.state.federalstates.stateAssetsPrice.currentPrice.value;
    const lastPriceValue =
      this.$store.state.federalstates.stateAssetsPrice.lastPrice.value;
    const formattedCurrentPrice = returnPriceFormatDE(
      this.$store.state.federalstates.stateAssetsPrice.currentPrice.value,
    );
    const currentDate = getCurrentDate();

    const preposition =
      this.$route.params.category === "saarland" ? "im" : "in";
    const { difference, numericCurrentPrice, numericLastPrice } =
      getPriceDifference(currentPriceValue, lastPriceValue);
    const { priceStatus, priceVariation } =
      numericCurrentPrice > numericLastPrice
        ? {
            priceStatus: "mehr",
            priceVariation: `einem Preisanstieg von ${getFormattedPercentage(
              currentPriceValue,
              lastPriceValue,
            )}`,
          }
        : {
            priceStatus: "weniger",
            priceVariation: `einer Preissenkung von ${getFormattedPercentage(
              currentPriceValue,
              lastPriceValue,
            )}`,
          };

    this.questionsList = [
      {
        question: `Was kostet Heizöl in ${translateStateName(
          this.$route.params.category,
        )}?`,
        answer: `<div><p style='padding: 10px 0;'>Der Liter Heizöl kostet am ${currentDate} ${preposition} ${translateStateName(
          this.$route.params.category,
        )} im Durchschnitt ${formattedCurrentPrice.replace(
          "€",
          "",
        )}Cent. Das sind ${String(difference / 100).replace(
          ".",
          ",",
        )} Cent ${priceStatus} als am Vortag.</p style='padding: 10px 0;'><p style='padding: 10px 0;'>Das entspricht ${priceVariation}.</p></div>`,
        visibility: true,
      },
    ];
    return this.questionsList;
  }

  get priceComparisonText(): string {
    return getPriceComparisonTextThreeMonths(
      this.statesOilPriceHistory.sateOilPriceData,
      this.statesOilPriceHistory.labels,
      this.currentAverageStatePrice,
    );
  }

  get priceComparisonText7Days(): string {
    return getPriceComparisonText7Days(
      this.statesOilPriceHistory.sateOilPriceData,
      this.statesOilPriceHistory.labels,
      this.currentAverageStatePrice,
    );
  }

  countyLinkMappings = {
    saalfeld: `/heizoelpreise/${encodeURIComponent("saalfeld|saale")}`,
    "bad%20homburg%20v.d.%20h%c3%b6he": `/heizoelpreise/${encodeURIComponent(
      "bad homburg v. d. höhe",
    )}`,
  };

  timeRangeOptions: Array<{ name: string; value: string }> = [
    { name: "3 Monate", value: "3" },
    { name: "6 Monate", value: "6" },
    { name: "1 Jahr", value: "12" },
    { name: "3 Jahre", value: "36" },
  ];

  get formattedTitle() {
    return getFormattedTitle("im Bundesland", this.$route.params.category);
  }

  get currentAverageStatePrice() {
    return returnPriceFormatDE(this.stateAssetsPrice.currentPrice.value);
  }

  get currentPrice(): number {
    return this.stateAssetsPrice.currentPrice.value;
  }

  get lastPrice(): number {
    return this.stateAssetsPrice.lastPrice.value;
  }

  get lastPriceDifferenceText(): string {
    return getPriceDifferenceText(this.currentPrice, this.lastPrice);
  }

  get historicalHighestDataState(): HistoricalOilPriceData[] {
    return getHistoricalData(this.stateYearStatistics, "highest");
  }

  get historicalLowestDataState(): HistoricalOilPriceData[] {
    return getHistoricalData(this.stateYearStatistics, "lowest");
  }
}
